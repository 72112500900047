<template>
  <main>
    <form @submit.prevent="submit" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
      <div class="mb-4">
        <label class="block text-grey-darker text-sm font-bold mb-2" for="username">
          What's your name?
        </label>
        <input
          v-model="input.username"
          autofocus
          autocomplete="off"
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
          id="username"
          type="text"
          placeholder="Username">
      </div>
      <div class="flex items-center justify-end">
        <button class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
          Sign In
        </button>
      </div>
    </form>
  </main>
</template>
<script>

  export default {
    data() {
      return {
        input: {
          username: ''
        }
      }
    },
    methods: {
      submit() {
        this.$emit('login', this.input.username);
      }
    }
  }
</script>
<style lang="scss" scoped>
  main {
    @apply text-left h-screen flex justify-center items-center bg-gray-100;

    > form {
      @apply w-full max-w-xs;
    }
  }

</style>

